const main = new Swiper('.swiper.promo_main', {
	slidesPerView: 1,
	centeredSlides: true,
	loop: true,
	speed: 700,
	pagination: {
    el: '.swiper-pagination',
		clickable: true
  },
	autoplay: {
		disableOnInteraction: false,
		delay: 4000
	}
});
// main-promo
const top_sales = new Swiper('.sales.top_sales .sales_body.swiper', {
	slidesPerView: 4,
	spaceBetween: 20,
	centeredSlides: false,
	loop: false,
	navigation: {
		prevEl: '.sales.top_sales .slide_controls .prev',
		nextEl: '.sales.top_sales .slide_controls .next'
	},
	breakpoints: {
    // mobile + tablet - 320-990
    320: {
      slidesPerView: 1
    },
    // desktop >= 991
    991: {
      slidesPerView: 4
    }
  }
});
const new_sales = new Swiper('.sales.new_sales .sales_body.swiper', {
	slidesPerView: 4,
	spaceBetween: 20,
	centeredSlides: false,
	loop: false,
	navigation: {
		prevEl: '.sales.new_sales .slide_controls .prev',
		nextEl: '.sales.new_sales .slide_controls .next'
	},
	breakpoints: {
    // mobile + tablet - 320-990
    320: {
      slidesPerView: 1
    },
    // desktop >= 991
    991: {
      slidesPerView: 4
    }
  }
});
const sale_sales = new Swiper('.sales.sale_sales .sales_body.swiper', {
	slidesPerView: 4,
	spaceBetween: 20,
	centeredSlides: false,
	loop: false,
	navigation: {
		prevEl: '.sales.sale_sales .slide_controls .prev',
		nextEl: '.sales.sale_sales .slide_controls .next'
	},
	breakpoints: {
    // mobile + tablet - 320-990
    320: {
      slidesPerView: 1
    },
    // desktop >= 991
    991: {
      slidesPerView: 4
    }
  }
});
const recommend_sales = new Swiper('.sales.recommend_sales .sales_body.swiper', {
	slidesPerView: 4,
	spaceBetween: 15,
	centeredSlides: false,
	loop: false,
	navigation: {
		prevEl: '.sales.recommend_sales .slide_controls .prev',
		nextEl: '.sales.recommend_sales .slide_controls .next'
	},
	breakpoints: {
    // mobile + tablet - 320-990
    320: {
      slidesPerView: 1
    },
    // desktop >= 991
    991: {
      slidesPerView: 4
    }
  }
});
const makeDotOptions = (max)=>{
	return {
		truncate: 'word',
		ellipsis: '...',
		height: max,
	}
}
$(function(){
	const mQuery = window.matchMedia('screen and (max-width: 768px)');
	if( mQuery.matches ){
		$('.menu.row_menu').on('click', function(){
			let $self = $(this);
			if( $self.hasClass('active') ){
				$.when($self.animate({'height': '13.33333vw'}, 250))
					.then(function(){
						$self.toggleClass('active')
					});
			} else {
				$.when($self.animate({'height': '73.33333vw'}, 250))
					.then(function(){
						$self.toggleClass('active')
					});
			}
		});
		$('.filter .expander_btn').on('click', ({target})=>{
			let $parent = $(target).closest('.catalog_body');
			let shift = $parent.hasClass('menu_open') ? '-76vw' : '0';
			$.when($parent.animate({'left':shift}), 250)
				.then(()=>{
					$parent.toggleClass('menu_open')
				});
		});
	}
	$('.table_sw').on('click', '.sw_item:not(.active)', function(){
		let index = $(this).attr('data-pointer');
		$('.table.table_sw').find('.sw_item.active').removeClass('active');
		$(this).addClass('active');
		$.when($('.table.table_body').find('.body_item.active').fadeOut())
			.then(function(){
				$('.table.table_body').find('.body_item.active').removeClass('active');
				$('.table.table_body').find(`.body_item[data-target=${index}]`).fadeIn().addClass('active');
			});
	});
	$('.ico.star, .ico.buy:not(.blocked)').on('click', function(){
		if( !$(this).hasClass('buy') ){
			$(this).toggleClass('un');
		} else {
			$(this).addClass('un');
		}
	});
	$('.filter').on('click', '.filter_item .del', function(){
		let id = $(this).closest('.filter_item').attr('data-id');
		$('.filter').find(`input#${id}`).prop("checked", false);
		$(this).closest('.filter_item').remove();
	});
	$('.filter').find('input[type="checkbox"]').on('change', function(){
		let id = $(this).attr('id'),
			name = $(this).closest('.chk_item').find('p.key').clone() //clone the element
			.children() //select all the children
			.remove() //remove all the children
			.end() //again go back to selected element
			.text();
		if( $(this).is(':checked') ){
			$('.filter').find('.box.used_data').find('.filter_body').prepend(`<div class="filter_item" data-id="${id}"><span>${name}</span><i class="ico del"></i></div>`)
		} else {
			$('.filter').find('.box.used_data').find('.filter_body').find(`.filter_item[data-id="${id}"]`).remove();
		}
	});
	$('.filter').find('.btn.clear_filter').on('click', function(){
		window.location = window.location.pathname.split("/").slice(0, 2).join("/");
		// $('.filter').find('.box.used_data').find('.filter_body').children().remove();
		// $('.filter').find('input[type="checkbox"]').prop("checked", false);
	});
	$('.filter').find('.ico.toggle').on('click', function(){
		let $self = $(this);
		if( $self.hasClass('open') ){
			$self.removeClass('open')
			$.when($self.closest('.box').find('.filter_body').animate({'opacity':'1'}, 250))
				.then(function(){
					$self.closest('.box').find('.filter_body').fadeIn(250);
				});
		} else {
			$self.addClass('open')
			$.when($self.closest('.box').find('.filter_body').animate({'opacity':'0'}, 250))
				.then(function(){
					$self.closest('.box').find('.filter_body').fadeOut(250);
				});
		}
	});
	if( $('.items_wrap').find('.item').length !== 0 ){
		$('.item').find('.info a.key').dotdotdot(makeDotOptions(26));
		$(document).on('pjax:success', ()=>{
			$('.item').find('.info a.key').dotdotdot(makeDotOptions(26));
		});
	}
	if( $('.sales_body').find('.item').length !== 0 ){
		$('.item').find('.info a.key').dotdotdot(makeDotOptions(26));
	}
	$('.partners').on('click', '.p_item', ({target})=>{
		const $self = $(target),
			content = $self.attr('content'),
			link = $self.attr('link'),
			$content = $self.closest('.partners').find('.content');
		if( content ){
			$content.find('.body').html(content);
			$content.find('a.link').attr('href', link);
			$content.toggleClass('front');
			$content.animate({'opacity': 1}, 150);
		}
	});
	$('.partners').on('click', '.content i', ({target})=>{
		const $modal = $(target).closest('.content');
		$modal.animate({'opacity':0}, 150, ()=>{
			$modal.toggleClass('front');
		});
	});
	if( $(".wrap_call_center").length !== 0 ){
		$('input[name="form_phone"]').mask('+38(099)999-99-99');
		$('input[type="text"], textarea[name="form_comment"]').on('change', ()=>{
			let name_flag = $('input[name="form_name"]').val() === '' ? false : true;
			let email_flag = $('input[name="form_email"]').val() === '' ? false : true;
			let phone_flag = $('input[name="form_phone"]').val() === '' ? false : true;
			let comment_flag = $('textarea[name="form_comment"]').val() === '' ? false : true;
			if( name_flag && email_flag && phone_flag && comment_flag ){
				$('a.btn.submit').removeAttr('disabled');
			} else {
				$('a.btn.submit').attr('disabled', true);
			}
		});
		$('a.btn.submit').on('click', ({target})=>{
			$('a.btn.submit').attr('disabled', true);
			let request_data = {};
			const $parent = $(target).closest('.wrap_form');
			request_data["client"] = {
				"name":$('input[name="form_name"]').val(),
				"phone":$('input[name="form_phone"]').val(),
				"email":$('input[name="form_email"]').val(),
				"comment":$('textarea[name="form_comment"]').val()
			};
			$.ajax({
				url:'https://mailsender.rb-idea.com/api/v1/send/callme',
				// url:'http://http://92.249.124.216:8111/api/v1/send/shop-cart',
				type:'POST',
				dataType: 'json',
				contentType: 'application/json',
				beforeSend: function(request) {
					request.setRequestHeader("token", "07612fdd-329c-4687-9f9f-382071103061"); // TODO Request TOKEN
				},
				data:JSON.stringify(request_data),
				success:(ret,__textStatus,xhr)=>{
					if( xhr.status === 200 ){
						if( ret.ok ){
							$parent.find('.sub_box').css({'top':0});
							setTimeout(()=>{
								window.location.reload();
							}, 3000);
						}
					}
				}
			})
			// $.ajax({
			// 	url:'/',
			// 	type:'POST',
			// 	dataType:'JSON',
			// 	data:{
			// 		name:$('input[name="form_name"]').val(),
			// 		email_flag:$('input[name="form_email"]').val(),
			// 		phone_flag:$('input[name="form_phone"]').val(),
			// 		comment_flag:$('textarea[name="form_comment"]').val()
			// 	},
			// 	success:(ret)=>{
			// 		if( ret.ok ){
			// 			window.location.reload;
			// 		}
			// 	}
			// })
		});
	}
	if( $('article.wrapper.info_page').length !== 0 && window.location.hash !== '' ){
		let $parent = $('article.wrapper.info_page'),
			anchor = window.location.hash,
			split = anchor.split('-');
		if( split.length == 2 && split[0].indexOf('info') > 0 ){
			$parent.find(`.sw_item[data-pointer=${split[1]}]`).trigger('click');
		}
	}
	$('footer.main_footer.dsk').on('click', '.btn.to_top', function(){
		$([document.documentElement, document.body]).animate({
			scrollTop: 0
		}, 1000);
	});
	if( $('.wrapper.cart_page').length !== 0 ){
		$('.wrapper.cart_page').on('click', '.box_head', ({target})=>{
			let $self = $(target),
				$parent = $self.closest('.box'),
				$wrapper = $parent.closest('.side');
			if( !$parent.hasClass('active') ){
				$.when($wrapper.find('.box.active').find('.box_body').slideUp(250))
					.then(()=>{
						$wrapper.find('.box.active').removeClass('active');
						$parent.find('.box_body').slideDown(250);
						$parent.addClass('active');
					});
			}
		});
	}
	// fill modal
	if( window.localStorage.getItem('cart') ){
		const stored = JSON.parse(window.localStorage.getItem('cart'));
		if( stored.length > 0 ){
			stored.forEach(el => {
				const $modal_wrap = $('.cart_modal_bg .cart_modal');
				renderItem(el, $modal_wrap);
				if( $(`.item[data-id="${el.id}"]`).length !== 0 ){
					$(`.item[data-id="${el.id}"]`).find('a.ico.buy').addClass('un');
				}
			});
			$('header').find('a.ico.cart').attr('data-count', recountPrice().quantity);
		}
		// trigger shopping Cart page
		if( $('.wrapper').hasClass('cart_page') ){
			const $items_wrap = $('.box.cart .items_list'),
				$modal_wrap = $('.cart_modal_bg .cart_modal .items_list'),
				$price_block = $('.box.mainbox');

			$items_wrap.append($modal_wrap.children().clone());
			$price_block.find('.total_price').html(`${recountPrice().price} ₴`);
			$price_block.find('.quantity').html(`(${recountPrice().quantity})`);

			$('input[name="form_phone"]').mask('+38(099)999-99-99');
			$('input[type="text"], input[type="checkbox"]').on('change', ()=>{
				let agree_flag = $('input#i-agree_cart').is(':checked');
				let email_flag = $('input[name="form_email"]').val() === '' ? false : true;
				let phone_flag = $('input[name="form_phone"]').val() === '' ? false : true;
				email_flag = email_flag ? $('input[name="form_email"]')[0].validity.valid : true;
				if( agree_flag && phone_flag && email_flag ){
					$('a.btn.continue').removeAttr('disabled');
				} else {
					$('a.btn.continue').attr('disabled', true);
					$('a.btn.order_fin').attr('disabled', true);
				}
			});
			$('a.btn.continue').on('click', ()=>{
				$('a.btn.order_fin').removeAttr('disabled');
			});
			$('a.btn.order_fin').on('click', ({target})=>{
				let request_data = {};
				let request_items = [];
				const stored = JSON.parse(window.localStorage.getItem('cart'));
				const $parent = $(target).closest('.box.mainbox');
				$.each(stored, (__n, el)=>{
					request_items.push({
						"id":el.id,
						"name":el.item.name,
						"quantity":el.quantity,
						"uri":el.item.url // USE FOR PART ITEM LINK (WITHOUT HOST)
					})
				});
				request_data["items"] = request_items;
				request_data["client"] = {
					// "name":
					"phone":$('input[name="form_phone"]').val(),
					"email":$('input[name="form_email"]').val(),
					// "comment":
				};
                                
                                let orderRows = $.extend({"_csrf": $('[name="csrf-token"]').attr('content')}, request_data);
                    
                                $.when(
                                    $.post($('[data-add-order-url]').attr('data-add-order-url'), orderRows),
                                    $.ajax({
					url:'https://mailsender.rb-idea.com/api/v1/send/shop-cart',
					// url:'http://http://92.249.124.216:8111/api/v1/send/shop-cart',
					type:'POST',
					dataType: 'json',
					contentType: 'application/json',
					beforeSend: function(request) {
						request.setRequestHeader("token", "07612fdd-329c-4687-9f9f-382071103061"); // TODO Request TOKEN
					},
					data:JSON.stringify(request_data),
					success:(ret,__textStatus,xhr)=>{
						
					}
				})
                                ).done(function(a1){
                                    if(a1[0].status === 'success'){
                                        window.localStorage.clear();
                                        $parent.find('.sub_box').css({'left':0});
                                        $('.inner_body.cart.page').find('input').attr('disabled', true);
                                        setTimeout(()=>{
                                                window.location.reload();
                                        }, 3000);
                                    }
				});       
			});
		}
	}
});
const mach_main_slider = new Swiper('section.promo .main_slider.swiper', {
	slidesPerView: 'auto',
	centeredSlides: false,
	loop: true
});
const mach_secondary_slider = new Swiper('section.promo .secondary_slider.swiper', {
	slidesPerView: 'auto',
	spaceBetween: 20,
	centeredSlides: false,
	loop: true,
	navigation: {
		prevEl: 'section.promo .poster_wrapper .slide_controls .prev',
		nextEl: 'section.promo .poster_wrapper .slide_controls .next'
	}
});
mach_main_slider.controller.control = mach_secondary_slider;
mach_secondary_slider.controller.control = mach_main_slider;
$('.wrapper.info_page, .wrapper.favorite').on('click', '.part_head', function(){
	let $self = $(this),
		$parent = $self.closest('.part_data')
	if( $parent.hasClass('active') ){
		$.when($parent.find('.part_body').slideUp()).then(function(){
			$parent.toggleClass('active');
		});
	} else {
		$.when($parent.find('.part_body').slideDown()).then(function(){
			$parent.toggleClass('active');
		});
	}
});
if( $('.wrapper.cart_page').length > 0 ){
	$('header').find('.user_holder').find('a.cart.only').show();
	$('header').find('.user_holder').find('a.cart:not(.only)').hide();
}
$('a.ico.cart.modal_open').on('click', ()=>{
	$('.cart_modal_bg .cart_modal').find('.summ_total p.key.red').html(`${recountPrice().price} ₴`);
	$('body').addClass('noscroll');
	$('.cart_modal_bg').fadeIn();
});
$('a.btn.clear_cart').on('click', ()=>{
	window.localStorage.clear();
	$('body').removeClass('noscroll');
	$('.cart_modal_bg').fadeOut();
	$('.cart_modal_bg .cart_modal').find('.c_item').remove();
	$('.cart_modal_bg .cart_modal').find('.q_holder').remove();
	$('.cart_modal_bg .cart_modal').find('.p_holder').remove();
	$('.cart_modal_bg .cart_modal').find('.summ_total p.key.red').html(`${0} ₴`);
});
$(document).on('click', '.data .modal_open', ({target})=>{
	let $container = $(target),
		item_data = JSON.parse($container.attr('content')),
		item_id = item_data.id,
		$modal_wrap = $('.cart_modal_bg .cart_modal');

	if( $modal_wrap.find(`[data-id=${item_id}]`).length === 0 ){
		// add item
		renderItem({'id':item_id,
			'quantity':1,
			'item':item_data}, $modal_wrap);
			storageWorker(item_data, 'add');
	}
	// else {
	// 	// remove item
	// 	$modal_wrap.find(`[data-id=${item_id}]`).remove();
	// 	storageWorker({"id":item_id}, 'remove');
	// }
	$modal_wrap.find('.summ_total p.key.red').html(`${recountPrice().price} ₴`);
	$('body').addClass('noscroll');
	$('.cart_modal_bg').fadeIn();
});
$(document).on('click', '.item .modal_open:not(.blocked)', ({target})=>{
	let $container = $(target).closest('.item'),
		item_data = JSON.parse($container.attr('content')),
		item_id = item_data.id,
		$modal_wrap = $('.cart_modal_bg .cart_modal'),
		$item_info = $('#item_tmpl').clone().removeAttr('id').removeAttr('style'),
		$item_quantity = $('#item_quantity_tmpl').clone().removeAttr('id').removeAttr('style'),
		$item_price = $('#item_price_tmpl').clone().removeAttr('id').removeAttr('style');

	if( $modal_wrap.find(`[data-id=${item_id}]`).length === 0 ){
		// add item
		renderItem({'id':item_id,
			'quantity':1,
			'item':item_data}, $modal_wrap);
			storageWorker(item_data, 'add');
	}
	// else {
	// 	// remove item
	// 	$modal_wrap.find(`[data-id=${item_id}]`).remove();
	// 	storageWorker({"id":item_id}, 'remove');
	// }
	$modal_wrap.find('.summ_total p.key.red').html(`${recountPrice().price} ₴`);
	$('body').addClass('noscroll');
	$('.cart_modal_bg').fadeIn();
});
// modal triggers
$('.cart_modal').on('click', 'a.ico.rm_item', ({target})=>{
	const $self = $(target),
		$container = $self.closest('.p_holder'),
		$modal_wrap = $('.cart_modal_bg .cart_modal');
	let item_id = parseInt($container.attr('data-id'), 10);
	storageWorker({"id":item_id}, 'remove');
	$modal_wrap.find(`[data-id=${item_id}]`).remove();
	$modal_wrap.find('.summ_total p.key.red').html(`${recountPrice().price} ₴`);
	if( $(`.item[data-id="${item_id}"]`).length !== 0 ){
		$(`.item[data-id="${item_id}"]`).find('a.ico.buy').removeClass('un');
	}
});
$('.cart_modal').on('change', 'input.quantity', ({target})=>{
	const $self = $(target),
		$container = $self.closest('.q_holder'),
		$modal_wrap = $('.cart_modal_bg .cart_modal');

	let item_id = parseInt($container.attr('data-id'), 10),
		input_val = parseInt($self.val(), 10);

	input_val = isNaN(input_val) ? 1 : input_val;
	storageWorker({'id':item_id}, 'set_q', input_val);
	$modal_wrap.find('.summ_total p.key.red').html(`${recountPrice().price} ₴`);
});
$('.cart_modal').on('click', 'a.btn.more, a.btn.less', ({target})=>{
	const $self = $(target),
		$container = $self.closest('.q_holder'),
		$input = $container.find('input.quantity');
	let input_val = parseInt($input.val(), 10);
	if( $self.hasClass('more') ){
		$input.val(++input_val).trigger('change');
	} else if( $self.hasClass('less') ){
		if( input_val > 1 ){
			$input.val(--input_val).trigger('change');
		}
	}
});
// shop-cart page
$('.box.cart').on('click', 'a.ico.rm_item', ({target})=>{
	const $self = $(target),
		$container = $self.closest('.p_holder'),
		$modal_wrap = $('.cart_modal_bg .cart_modal'),
		$box_wrap = $('.box.cart'),
		$price_block = $('.box.mainbox');
	let item_id = parseInt($container.attr('data-id'), 10);
	storageWorker({"id":item_id}, 'remove');
	$modal_wrap.find(`[data-id=${item_id}]`).remove();
	$modal_wrap.find('.summ_total p.key.red').html(`${recountPrice().price} ₴`);
	$box_wrap.find(`[data-id=${item_id}]`).remove();
	$price_block.find('.total_price').html(`${recountPrice().price} ₴`);
	$price_block.find('.quantity').html(`(${recountPrice().quantity})`);
});
$('.box.cart').on('change', 'input.quantity', ({target})=>{
	const $self = $(target),
		$container = $self.closest('.q_holder'),
		$modal_wrap = $('.cart_modal_bg .cart_modal'),
		$price_block = $('.box.mainbox');

	let item_id = parseInt($container.attr('data-id'), 10),
		input_val = parseInt($self.val(), 10);

	input_val = isNaN(input_val) ? 1 : input_val;
	storageWorker({"id":item_id}, 'set_q', input_val);
	$modal_wrap.find('.summ_total p.key.red').html(`${recountPrice().price} ₴`);
	$price_block.find('.total_price').html(`${recountPrice().price} ₴`);
	$price_block.find('.quantity').html(`(${recountPrice().quantity})`);
});
$('.box.cart').on('click', 'a.btn.more, a.btn.less', ({target})=>{
	const $self = $(target),
		$container = $self.closest('.q_holder'),
		$input = $container.find('input.quantity');
	let input_val = parseInt($input.val(), 10);
	if( $self.hasClass('more') ){
		$input.val(++input_val).trigger("change");
	} else if( $self.hasClass('less') ){
		if( input_val > 1 ){
			$input.val(--input_val).trigger("change");
		}
	}
});
const recountPrice = () => {
	if( window.localStorage.getItem('cart').length !== 0 ){
		const stored = JSON.parse(window.localStorage.getItem('cart'));
		let price_total = 0,
			quantity_total = 0;
		stored.forEach(el => {
			price_total += (el.quantity * el.item.price);
			quantity_total += el.quantity;
		});
		return {'price':price_total,'quantity':quantity_total};
	}
	return {'price':0,'quantity':0};
}
const storageWorker = (item, action, quantity) => {
	let local = window.localStorage;
	let storage = local.getItem('cart');
	switch( action ){
		case 'add':
			if( storage ){
				let data = JSON.parse(storage),
					flag = false;
				data.forEach((el,index) => {
					if( el.id == item.id ){
						++data[index].quantity;
						flag = true;
					}
				});
				if( !flag ){
					data.push({
						"id": item.id,
						"quantity": 1,
						"item": item
					});
				}
				local.setItem('cart', JSON.stringify(data));
			} else {
				local.setItem('cart', JSON.stringify([{
					"id": item.id,
					"quantity": 1,
					"item": item
				}]));
			}
			break
		case 'set_q':
			if( storage ){
				let data = JSON.parse(storage);
				data.forEach((el,index) => {
					if( el.id == item.id ){
						data[index].quantity = quantity;
					}
				});
				local.setItem('cart', JSON.stringify(data));
			} else {
				local.setItem('cart', JSON.stringify([{
					"id": item.id,
					"quantity": 1,
					"item": item
				}]));
			}
			break
		case 'remove':
			if( storage ){
				let data = JSON.parse(storage),
					new_array = [];
				data.forEach(el => {
					if( el.id !== item.id ){
						new_array.push(el);
					}
				});
				local.setItem('cart', JSON.stringify(new_array));
			}
			break
		default:
			alert('unsupported action. terminate');
	}
	$('header').find('a.ico.cart').attr('data-count', recountPrice().quantity);
	return true;
}
const renderItem = (element, $wrapper) => {
	let $item_info = $('#item_tmpl').clone().removeAttr('id').removeAttr('style'),
		$item_quantity = $('#item_quantity_tmpl').clone().removeAttr('id').removeAttr('style'),
		$item_price = $('#item_price_tmpl').clone().removeAttr('id').removeAttr('style');

	$item_info.attr('data-id', element.id);
	$item_info.find('.poster').attr('style', `--i-img:url(${element.item.poster})`);
	let item_name = element.item.name.length > 25 ? `${element.item.name.substr(0, 25)}...` : element.item.name;
	$item_info.find('.info p.key').html(item_name);
	let item_cat = element.item.cat.length > 30 ? `${element.item.cat.substr(0, 30)}...` : element.item.cat;
	$item_info.find('.info p.group').html(item_cat);
	$item_info.find('.info p.weight span').html(element.item.weight);

	$wrapper.find('.item_wrap.alt').append($item_info);
	$wrapper.find('.item_wrap.alt p.key').dotdotdot(makeDotOptions(25));

	$item_quantity.attr('data-id', element.id);
	$item_quantity.find('input.quantity').val(element.quantity);
	$wrapper.find('.quantity_wrap.alt').append($item_quantity);
	// $wrapper.find(`.q_holder[data-id="${element.id}"]`).find('input.quantity').trigger('change');

	$item_price.attr('data-id', element.id);
	let prev_price = element.item.prev ? `${element.item.prev} ₴` : '';
	$item_price.find('p.price').attr('data-prev', prev_price);
	$item_price.find('p.price').html(`${element.item.price} ₴`);

	$wrapper.find('.price_wrap.alt').append($item_price);
}
$('.cart_modal').on('click', '.btn.continue, .ico.del, .btn.close_cart', function(){
	$('body').removeClass('noscroll');
	$('.cart_modal_bg').fadeOut();
});
$('.filter').on('click', '.all_trigger', function(){
	$(this).closest('.box').find('.collapse').removeClass('collapse');
	$(this).hide();
});
